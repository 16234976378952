import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { ParagraphSearchQuery } from 'src/app/paragraph-review/store/reducer';
import { Dictionary, LabelAnnotations } from '../models/Annotations';
import { LabelParagraphDTO } from '../models/LabelParagraphDTO';
import { BulkActionBasePayload, FeedbackResponseTypeEnum } from '../models/models';
import { ClusterSearchQuery } from '../models/ClusterSearchQuery';
import { ClusterMetadataResponse } from '../models/ClusterMetadata';

@Injectable({
  providedIn: 'root',
})
export class ProvisionService {
  constructor(private http: HttpClient) { }
  searchProvisions(obj: ParagraphSearchQuery) {
    return this.http.post<any>('api/provision/search', obj);
  }

  classifyProvisionWithModel(provisionId: number, modelDeploymentId: number) {
    return this.http.get<any>(`api/provision/classify/${provisionId}/${modelDeploymentId}`);
  }

  classifyManyProvisionsWithModel(obj: BulkActionBasePayload, modelDeploymentId: number) {
    return this.http.post<any>(`api/provision/classify/sampleset/${modelDeploymentId}`, obj);
  }

  addProvisionTags(provisionId: number, provisionTag: string) {
    return this.http.post<any>(`api/provision/${provisionId}/tags`, { provisionTag });
  }

  removeProvisionTags(provisionId: number, provisionTag: string) {
    return this.http.request('delete', `api/provision/${provisionId}/tags`,
      { body: { provisionTag }});
  }

  addParagraphAnnotation(obj: LabelParagraphDTO) {
    return this.http.post<any>('api/provision/addlabels', obj);
  }

  removeUserAnnotation(obj: LabelParagraphDTO) {
    return this.http.post<Dictionary<LabelAnnotations[]>>('api/provision/removelabels', obj);
  }

  confirmParagraph(provisionId) {
    return this.http.post<any>(`api/provision/${provisionId}/confirm`, null);
  }

  confirmParagraphSet(obj: BulkActionBasePayload) {
    return this.http.post<any>(`api/provision/bulk/confirm`, obj);
  }


  unconfirmParagraph(provisionId) {
    return this.http.post<any>(`api/provision/${provisionId}/unconfirm`, null);
  }

  hideParagraph(provisionId) {
    return this.http.post<any>(`api/provision/${provisionId}/hide`, null);
  }

  unhideParagraph(provisionId) {
    return this.http.post<any>(`api/provision/${provisionId}/unhide`, null);
  }

  hideParagraphSet(obj: BulkActionBasePayload) {
    return this.http.post<any>(`api/provision/bulk/hide`, obj);
  }

  unhideParagraphSet(obj: BulkActionBasePayload) {
    return this.http.post<any>(`api/provision/bulk/unhide`, obj);
  }

  bulkFindConfoundingScores(obj: BulkActionBasePayload) {
    return this.http.post<any>(`api/provision/bulk/findconfoundingscores`, obj);
  }

  bulkTagsAdd(obj: any) {
    return this.http.post<any>(`api/provision/bulk/tags/add`, obj);
  }

  bulkTagsRemove(obj: any) {
    return this.http.post<any>(`api/provision/bulk/tags/remove`, obj);
  }

  bulkRejectParagraphs(obj: any) {
    return this.http.post<any>(`api/provision/bulk/reject`, obj);
  }

  bulkUnconfirmParagraph(obj: any) {
    return this.http.post<any>(`api/provision/bulk/unconfirm`, obj);
  }

  searchParagraphs(obj: ParagraphSearchQuery) {
    return this.http.post<any>('api/search', obj);
  }

  searchParagraph(provisionId: number) {
    return this.http.get<any>(`api/search/paragraph/${provisionId}`);
  }

  rejectParagraph(provisionId: number, rejectionReason: string, responseType: FeedbackResponseTypeEnum) {
    return this.http.post<any>(`api/provision/reject`, { provisionId, rejectionReason, responseType });
  }

  shareSearch(dto: ParagraphSearchQuery) {
    return this.http.post<any>(`api/search/share`, dto);
  }

  searchParagraphCluster(obj: ClusterSearchQuery) {
    return this.http.post<ClusterMetadataResponse>('api/search/clustermetadata', obj);
  }

  loadSearch(queryParam: string) {
    if (queryParam && queryParam.length !== 0) {
      return this.http.get<ParagraphSearchQuery>(`api/search/query/${queryParam}`);
    }
    return of(null);
  }

  addExemplar(paragraphId: number) {
    return this.http.post<any>(`api/provision/${paragraphId}/example`, '', { observe: 'response' });
  }

  deleteExemplar(paragraphId: number) {
    return this.http.delete<any>(`api/provision/${paragraphId}/example`, { observe: 'response' });
  }



}
