import {
  DateFilterOperatorEnum,
  ParagraphSearchQuery,
  SortByFieldEnum,
  SortByLabelEnum
} from 'src/app/paragraph-review/store/reducer';
import { LabelAnnotations, StringMap } from './Annotations';
import { Label } from './Label';
import { CustomAttribute } from './CustomAttribute';
import { Offset } from './Offset';

export enum ActionEnum {
  MergeLabels = 1,
  CopyLabelsWithinOntology = 2,
  CopyLabelsToOntology = 3,
  TrainingComplete = 4,
  ProvisionBulkTagAdd = 5,
  ProvisionBulkTagRemove = 6,
  ProvisionBulkClassify = 7,
  ProvisionBulkHide = 8,
  ProvisionBulkReject = 9,
  ProvisionBulkFindConfoundingScore = 10,
  DeploymentComplete = 11,
  ProvisionBulkConfirm = 12,
  ProvisionBulkUnconfirm = 13,
  OntologyCopied = 14,
  ExperimentComplete = 15,
  ProvisionBulkUnhide = 16,
  ConflictingReportComplete = 17
}
export enum NotificationTypeEnum {
  Info = 1,
  Error = 2,
}

export enum LabelTypeEnum {
  System = 1,
  Custom = 2,
}

export enum FeedbackResponseTypeEnum {
  Disregarded = 1,
  NotAddedToModel = 2,
  InformationalNoAction = 3,
  DuplicateActionTakenElsewhere = 4,
  CleanUp = 5,
}

export enum TestSetTypeEnum {
  UserDefined = 'UserDefined',
  MachineDefined = 'MachineDefined'
}

export interface User {
  id: number;
  name: string;
  email: string;
  displayName: string;
}

export interface Notification {
  description: string;
  createdOn: Date;
  actionType: ActionEnum;
  userId: number;
  notificationType: NotificationTypeEnum;
  notificationId: string;
  progress: number;
}

export interface Entity {
  id: number;
  name: string;
  fields: Field[];
  ontology: string;
  definition: string;
  example: string;
}

export interface Field {
  id: number;
  name: string;
  isVisible: boolean;
  labels: Label[];
  dataType: DataType;
  unitOfMeasure: UnitOfMeasure;
  entityId: number;
  definition: string;
  example: string;
  showProvision: boolean;
  attributes: CustomAttribute[];
  parentId?: number | null;
  ontology?: string | null;
}

export enum DataType {
  Number = 1,
  Text = 2,
  Date = 3,
  Boolean = 4,
}

export enum ExtractorType {
  GATE = 1,
  BERT = 2,
  GPT = 3,
}

export enum UnitOfMeasure {
  None = 0,
  Acres = 1,
  Days = 2,
  Months = 3,
  Years = 4,
  Currency = 5,
  Inches = 6,
  Feet = 7,
  Percentage = 8,
}

export enum TrainingStatusEnum {
  InProgress = 1,
  Completed = 2,
  Error = 3,
  NotTrained = 4,
}

export enum TrainingType {
  Mapping = 0,
  Legacy = 1,
  Bert = 2,
}

export enum ModelStatusEnum {
  InReview = 1,
  Production = 2,
  Deprecated = 3,
}

export enum DeploymentStatus {
  SUCCESSFUL = 1,
  FAILED = 2,
  REQUESTED = 3,
  IN_PROGRESS = 4,
}

export enum ModelDeploymentStatus {
  SUCCESSFUL = 'SUCCESSFUL',
  FAILED = 'FAILED',
  REQUESTED = 'REQUESTED',
  IN_PROGRESS = 'IN_PROGRESS',
  REJECTED = 'REJECTED',
  DECOMMISSIONED = 'DECOMMISSIONED'
}

export enum ModelTrainingStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  SUBMITTED = 'SUBMITTED',
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  SUCCESSFUL = 'SUCCESSFUL'
}

export interface Model {
  id: string;
  createdOn: Date;
  trainingCompletedOn: Date;
  comments: string;
  ontologyId: string;
  createdBy: User;
  trainingStatus: TrainingStatusEnum;
  status: ModelStatusEnum;
  promotedOn: Date;
  trainingType: TrainingType;
}

export interface ModelDetails {
  id: string;
  createdOn: Date;
  trainingCompletedOn: Date;
  comments: string;
  ontologyId: string;
  createdBy: User;
  trainingStatus: TrainingStatusEnum;
  status: ModelStatusEnum;
  promotedOn: Date;
  lastDeployedToDevOn: Date;
  lastDeployedToDevBy: string;
  lastDeployedToPrdOn: Date;
  lastDeployedToPrdBy: string;
  trainingType: TrainingType;
  datasetId: number;
  datasetName: string;
  datasetCreatedOn: Date;
}

export interface ModelResult {
  count: number;
  model: Model;
}

export interface LabelMapping {
  id: number;
  name: string;
  labels: Label[];
}

export interface AnalyazeModelText {
  ontology: string;
  text: string;
  modelId: string;
}

export interface AnalyzeTextResult {
  id: string;
  annotations: StringMap<LabelAnnotations[]>;
  text: string;
  modelId: string;
}

export interface RangeSelected {
  offset: Offset;
  uniqueId: number;
}

export interface RangeRemoved {
  offsets: Offset[];
  uniqueId: number;
}

export interface DateOperator {
  name: string;
  value: DateFilterOperatorEnum;
}

export const DateOperators: DateOperator[] = [
  { name: 'Any Time', value: DateFilterOperatorEnum.AnyTime },
  { name: 'After', value: DateFilterOperatorEnum.After },
  { name: 'Before', value: DateFilterOperatorEnum.Before },
  { name: 'On', value: DateFilterOperatorEnum.On },
];

export interface SortByField {
  name: string;
  value: SortByFieldEnum;
}

export const SortByFields: SortByField[] = [
  { name: 'Confirmed On', value: SortByFieldEnum.ConfirmedOn },
  { name: 'Uploaded On', value: SortByFieldEnum.UploadedOn },
  { name: 'Paragraph Id', value: SortByFieldEnum.ParagraphId },
  { name: 'Similarity Score', value: SortByFieldEnum.SimilarityScore },
  { name: 'Confounding Score', value: SortByFieldEnum.ConfoundingScore },
  { name: 'Machine Label', value: SortByFieldEnum.MachineLabel },
  { name: 'Paragraph Index', value: SortByFieldEnum.ParagraphIndex }
];

export interface SortByLabel {
  name: string;
  value: SortByLabelEnum;
}
export const SortByLabels: SortByLabel[] = [
  { name: 'Name', value: SortByLabelEnum.Name },
  { name: 'Confirmed Samples Count', value: SortByLabelEnum.ConfirmedSamplesCount },
];

export const SortByTenantFields: SortByField[] = [
  { name: 'Similarity Score', value: SortByFieldEnum.SimilarityScore },
  { name: 'Created On', value: SortByFieldEnum.CreatedOn },
  { name: 'Length', value: SortByFieldEnum.Length },
];

export interface BulkActionBasePayload {
  searchQuery: ParagraphSearchQuery;
  paragraphIds: number[];
  excludedParagraphIds: number[];
}
export interface Highlight {
  annotations: LabelAnnotations[];
  text: string;
  uniqueId: number;
}

export interface DeploymentModel {
  id: number;
  modelId: string;
  deployedOn: Date;
  deployedBy: string;
  requestedOn: Date;
  requestedBy: string;
  ontology: string;
  status: DeploymentStatus;
}

export interface DeploymentModels {
  sme: DeploymentModel;
  qa: DeploymentModel;
  production: DeploymentModel;
  requested: DeploymentModel;
}

export enum ModelDeployActionEnum {
  Reject = 1,
  Cancel = 2,
}

export enum EnvironmentEnum {
  PRODUCTION = 'production',
  SMEDEV = 'smedev',
  QACANDIDATE = 'qacandidate'
}

export interface DevelopmentTenant {
  name: string;
  value: EnvironmentEnum;
}

export const DevelopmentTenants: DevelopmentTenant[] = [
  { name: 'SMEDEV', value: EnvironmentEnum.SMEDEV },
  { name: 'QACANDIDATE', value: EnvironmentEnum.QACANDIDATE },
];

export interface Extractor {
  name: string;
  id: number;
  definition: string;
  example: string;
  dataType: DataType;
  type: ExtractorType;
  createdByDisplayName: string;
  ontologyId: string;
  associatedLabels: string[];
  associatedOntologies: string[];
}
export interface GPTExtractor extends Extractor {
  labelId: number;
}

export interface ExtractorPredictionRequest {
  text: string;
  extractorId?: number;
  ontologyId: string;
}

export interface ExtractorPredictionResult {
  name: string;
  start: number;
  end: number;
}


export interface ExtractorPredictionResult {
  id: string;
  annotations: StringMap<ExtractorPredictionResult[]>;
  text: string;
  ontologyId: string;
  extractorId: number;
}

export interface ExtractorUpdateRequest {
  name: string;
  description: string;
  dataType: number;
  labelId: number;
}
export interface ExtractorCreateRequest extends ExtractorUpdateRequest {
  ontologyId: string;
}
export enum LabelInsightsUserType {
  Internal = 'internal',
  ThirdParty = '3rd_party',
  TR_Internal = 'tr_internal',
}

export enum UserRole {
  User = 'User',
  Admin = 'Admin'
}

export enum DeploymentType {
  Standard = 'Standard',
  Custom = 'Custom'
}

export enum DeploymentEndPoint {
  ClauseBank = 'Clause Finder TR Documents (Practical Law Standard and SEC)',
  ThoughtTrace = 'Document Intelligence Workspace'
}

export enum DeploymentEndPointDesc {
  ClauseBank = 'Clause Finder TR Documents',
  ThoughtTrace = 'Document Intelligence Workspace'
}

export interface DeployModelRequest {
  modelId: string,
  deploymentType: string,
  endpoint: string,
  workspace?: string,
}

export enum DeploymentAction {
  Approve = 'APPROVE',
  Reject = 'REJECT'
}

export enum ConflictReportColumn {
  Selected = 'selected',
  ParagraphId = 'ParagraphId',
  ConflictingScore = 'ConflictingScore',
  SimilarityScore = 'similarityScore',
  UserLabels = 'userLabels',
  MissingLabels = 'missingLabels',
  DifferentLabels = 'differentLabels',
  Ontology = 'ontology',
  LastEdited = 'LastEdited',
  Tags = 'tags',
  Expand = 'expand'
}

export enum ConflictReportTags {
  Assign = 'Assign',
  Status = 'Status'
}

export enum ConflictReportTagStatus {
  InProgress = 'In Progress',
  IgnoreConflict = 'Ignore Conflict',
  Resolved = 'Resolved',
}

export interface ConflictReportSelection {
  column: ConflictReportColumn;
  isSelected: boolean;
}

export enum ConflictReportStatus {
  Processing,
  Successful,
  NotFound,
}

export interface ModelTypes {
  name: string
  modelType: TrainingType
}

export interface ExtractorFilter {
  name: string;
  associatedOntologies: string;
  extractorType: number;
}
