import { AsyncPipe, CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TagInputModule } from 'ngx-chips';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
// import { MaterialFileInputModule } from 'ngx-material-file-input';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MaterialModule } from '../material/material.module';
import { CustomTagComponent } from './components/custom-tag/custom-tag.component';
import { DateFilterComponent } from './components/date-filter/date-filter.component';
import { FieldTreeViewComponent } from './components/field-tree-view/field-tree-view.component';
import { HighlightComponent } from './components/highlight/highlight.component';
import { InlineEditComponent } from './components/inline-edit/inline-edit.component';
import { InlineEditService } from './components/inline-edit/inline-edit.service';
import { MatSelectAutocompleteComponent } from './components/mat-select-autocomplete/mat-select-autocomplete.component';
import { MatSelectSearchComponent } from './components/mat-select-search/mat-select-search.component';
import { NotificationActionComponent } from './components/notification-action/notification-action.component';
import { TagComponent } from './components/tag/tag.component';
import { TreeViewComponent } from './components/tree-view/tree-view.component';
import { CommentDialogComponent } from './dialogs/comment-dialog/comment-dialog.component';
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog/confirmation-dialog.component';
import { CreateLabelDialogComponent } from './dialogs/create-label-dialog/create-label-dialog.component';
import {
  CreateTextSampleDialogComponent,
} from './dialogs/create-text-sample-dialog/create-text-sample-dialog.component';
import { UpdateTagsDialogComponent } from './dialogs/update-tags-dialog/update-tags-dialog.component';
import { LIAccessDirective } from './directives/li-access.directive';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { EnumIteratorPipe, SortMapPipe } from './pipes/enum-iterator.pipe';
import { FilterIndexPipe, NullPipe } from './pipes/index.pipe';
import {
  FilterLabelPipe,
  LabelConfidencePipe,
  LabelValuePipe,
  MachineLabelPipe,
  UserLabelPipe,
} from './pipes/labels.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { TrustHtmlPipe } from './pipes/trustHtml-pipe';
import { NotificationComponent } from './components/notification/notification.component';
import { MatSelectConfidenceComponent } from './components/mat-select-confidence/mat-select-confidence.component';
import { ExampleMainComponent } from './components/example-main/example-main.component';
import { ExampleViewComponent } from './components/example-view/example-view.component';
import {
  MatSelectAutocompleteMultiComponent,
} from './components/mat-select-autocomplete-multi/mat-select-autocomplete-multi.component';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { MatDividerModule } from '@angular/material/divider';
import { SizeSelectionViewComponent } from './components/size-selection-view/size-selection-view.component';
import { ModelRefinementOptionsDialogComponent } from './dialogs/model-refinement-options-dialog/model-refinement-options-dialog.component';
import { SnapshotDatasetComponent } from './components/snapshot-dataset/snapshot-dataset.component';
import { TrainModelComponent } from './components/train-model/train-model.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RunExperimentComponent } from './components/run-experiment/run-experiment.component';
import { ConflictingReportComponent } from './components/conflicting-report/conflicting-report.component';
import { SelectConfidenceDialogComponent } from './dialogs/select-confidence-dialog/select-confidence-dialog.component';
import { DatePipe } from '@angular/common';
import { DataGenieDialogComponent } from './dialogs/data-genie-dialog/data-genie-dialog.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
@NgModule({
    declarations: [
        MachineLabelPipe,
        UserLabelPipe,
        FilterLabelPipe,
        TrustHtmlPipe,
        FilterIndexPipe,
        TagComponent,
        ConfirmationDialogComponent,
        CreateTextSampleDialogComponent,
        CustomTagComponent,
        CreateLabelDialogComponent,
        LabelValuePipe,
        InlineEditComponent,
        EnumIteratorPipe,
        CustomDatePipe,
        MatSelectAutocompleteComponent,
        TreeViewComponent,
        OrderByPipe,
        NotificationActionComponent,
        HighlightComponent,
        MatSelectSearchComponent,
        CommentDialogComponent,
        NullPipe,
        DateFilterComponent,
        LabelConfidencePipe,
        UpdateTagsDialogComponent,
        SortMapPipe,
        LIAccessDirective,
        FieldTreeViewComponent,
        NotificationComponent,
        MatSelectConfidenceComponent,
        ExampleMainComponent,
        ExampleViewComponent,
        MatSelectAutocompleteMultiComponent,
        MessageDialogComponent,
        SizeSelectionViewComponent,
        ModelRefinementOptionsDialogComponent,
        SnapshotDatasetComponent,
        TrainModelComponent,
        RunExperimentComponent,
        ConflictingReportComponent,
        SelectConfidenceDialogComponent,
        DataGenieDialogComponent,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        TagInputModule,
        FlexLayoutModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        FormsModule,
        NgxSpinnerModule,
        NgxMatSelectSearchModule,
        InfiniteScrollModule,
        MatDividerModule,
        MatFormFieldModule,
        MatInputModule,
        AsyncPipe,
    ],
    exports: [
        MachineLabelPipe,
        UserLabelPipe,
        FilterLabelPipe,
        TrustHtmlPipe,
        MaterialModule,
        TagInputModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        FormsModule,
        NgxSpinnerModule,
        TagComponent,
        FilterIndexPipe,
        LabelValuePipe,
        InlineEditComponent,
        CustomTagComponent,
        EnumIteratorPipe,
        CustomDatePipe,
        MatSelectAutocompleteComponent,
        TreeViewComponent,
        OrderByPipe,
        HighlightComponent,
        MatSelectSearchComponent,
        NullPipe,
        DateFilterComponent,
        InfiniteScrollModule,
        LabelConfidencePipe,
        UpdateTagsDialogComponent,
        SortMapPipe,
        LIAccessDirective,
        FieldTreeViewComponent,
        NotificationComponent,
        MatSelectConfidenceComponent,
        ExampleMainComponent,
        ExampleViewComponent,
        MatSelectAutocompleteMultiComponent,
        SizeSelectionViewComponent,
    ],
    providers: [InlineEditService, DatePipe],
})
export class SharedModule { }
