<div
  fxLayout="row"
  class="action-bar"
  [ngStyle]="reviewView === ReviewViewEnum.ParagraphSearch && { 'margin-left': '8px' }"
>
  <div
    fxLayoutGap="1em"
    [fxFlex]="reviewView === ReviewViewEnum.ParagraphSearch ? '62' : ''"
    fxLayoutAlign="start center"
    *ngIf="isParent"
    class="left-section"
  >
    <ng-container *ngIf="reviewView === ReviewViewEnum.ParagraphSearch">
      <mat-checkbox
        [(ngModel)]="checkPageItemsState"
        color="primary"
        (change)="checkPageItemsChange($event)"
        [disabled]="!paragraphIds"
      ></mat-checkbox>
    </ng-container>
    <p *ngIf="checkedParagraphCount > 0" style="font-weight: 500">
      {{ checkedParagraphCount }} of {{ total }} selected
    </p>
    <div *ngIf="checkedParagraphCount != total && checkedParagraphCount > 0">
      <button class="large-button" mat-raised-button (click)="checkAllChange(true)" color="primary">
        Select All Results
      </button>
    </div>
    <div *ngIf="checkedParagraphCount > 0 || clusterSelection?.selectedClusters?.length > 0">
      <button mat-raised-button (click)="clearSelectedItems()" color="primary">Clear Selection</button>
    </div>
    <div *ngIf="reviewView === ReviewViewEnum.SideBySideConflict">
      <button mat-button (click)="navigateToPreviousView()" class="return-button" color="primary">
        <mat-icon>arrow_back</mat-icon>
        <span>Back to Report</span>
      </button>
    </div>
    <div *ngIf='reviewView === ReviewViewEnum.DatasetSideBySideConflict'>
      <button mat-button (click)="navigateToPreviousView()" class="return-button" color="primary">
        <mat-icon>arrow_back</mat-icon>
        <span>Back to Report</span>
      </button>
    </div>
    <button
      *ngIf="reviewView === ReviewViewEnum.ParagraphSearch || reviewView === ReviewViewEnum.ClusterView"
      mat-raised-button
      color="primary"
      [disabled]="
        checkedParagraphIds.length === 0 && !checkAllState && clusterSelection?.selectedClusters?.length === 0
      "
      [matMenuTriggerFor]="menu"
    >
      Actions
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="bulkAddTags()">
        <mat-icon>style</mat-icon>
        <span>Add Tags</span>
      </button>
      <button mat-menu-item (click)="bulkRemoveTags()">
        <mat-icon>style</mat-icon>
        <span>Remove Tags</span>
      </button>
      <ng-container *ngIf="reviewView !== ReviewViewEnum.ClusterView">
        <button mat-menu-item (click)="classifySampleSet()">
          <mat-icon>cloud_upload</mat-icon>
          <span>Classify</span>
        </button>
        <button mat-menu-item (click)="hideParagraphSet()">
          <mat-icon>visibility_off</mat-icon>
          <span>Hide</span>
        </button>
        <button mat-menu-item (click)="unhideParagraphSet()">
          <mat-icon>visibility</mat-icon>
          <span>Unhide</span>
        </button>
        <button mat-menu-item (click)="rejectParagraphSet()">
          <mat-icon>thumb_down</mat-icon>
          <span>Reject</span>
        </button>
        <button mat-menu-item (click)="bulkFindConfoundingScores()">
          <mat-icon>swap_horizontal_circle</mat-icon>
          <span>Find Confounding Scores</span>
        </button>
        <button mat-menu-item (click)="confirmParagraphSet()">
          <mat-icon>check_circle</mat-icon>
          <span>Confirm</span>
        </button>
        <button mat-menu-item (click)="bulkUnconfirmParagraphs()">
          <mat-icon>radio_button_unchecked</mat-icon>
          <span>Unconfirm</span>
        </button>
      </ng-container>
      <button *ngIf="(featureFlags | async)?.TopicDiscovery" mat-menu-item (click)="zoomTo()">
        <mat-icon>zoom_in</mat-icon>
        <span>Zoom To</span>
      </button>
    </mat-menu>
    <button
      mat-raised-button
      color="primary"
      (click)="getShareableLink()"
      [matMenuTriggerFor]="shareMenu"
      *ngIf="reviewView !== ReviewViewEnum.ClusterView"
    >
      Share
    </button>
    <mat-menu #shareMenu="matMenu">
      <p class="share-header">Share a deep link of the current search query</p>
      <mat-form-field (click)="preventClose($event)" class="share-link-input">
        <input [readonly]="true" #shareLinkInput type="text" [(ngModel)]="shareLink" matInput />
        <button
          matSuffix
          matTooltip="Copy To Clipboard"
          color="primary"
          [disabled]="shareLink === 'Loading...'"
          (click)="copyToClipBoard($event, shareLinkInput)"
          mat-icon-button
        >
          <mat-icon style="font-size: large">file_copy</mat-icon>
        </button>
        <!-- <mat-icon (click)="copyToClipBoard($event, shareLinkInput)" matSuffix>file_copy</mat-icon> -->
      </mat-form-field>
    </mat-menu>
    <button
      *ngIf="reviewView !== ReviewViewEnum.ClusterView"
      mat-raised-button
      class="large-button"
      color="primary"
      (click)="toggleMetaData()"
    >
      Toggle Meta Data
    </button>
    <mat-chip class="user-label" *ngIf="active">
      <span
        class="label-chip-text"
        [ngClass]="{ 'label-large-text': addEllipsisToLabel(active.name) }"
        [matTooltip]="active.name"
        matTooltipClass="all-text-tooltip"
        >{{ active.name }}
      </span>
      <mat-icon class="sample-icon" matTooltip="View definition and Samples" (click)="showDefinitionAndExamples(active)"
        >library_books</mat-icon
      >
      <mat-icon matTooltip="Remove active label" (click)="removeActiveLabel()" matChipRemove>cancel</mat-icon>
    </mat-chip>
  </div>
  <div
    *ngIf="reviewView === ReviewViewEnum.ParagraphSearch"
    fxLayoutGap="1em"
    fxFlex="38"
    class="right-menu"
    fxLayoutAlign="end center"
  >
    <button color="accent" mat-raised-button [matMenuTriggerFor]="training">
      <mat-icon>train</mat-icon>
      Train
    </button>
    <mat-menu #training="matMenu">
      <button mat-menu-item (click)="trainModel(TrainingType.Legacy)">
        <span>LEGACY</span>
      </button>
      <button mat-menu-item (click)="trainModel(TrainingType.Bert)">
        <span>BERT</span>
      </button>
    </mat-menu>
    <button
      *ngIf="(featureFlags | async)?.TopicDiscovery && searchPredicate?.clusterIds?.length !== 1"
      mat-raised-button
      color="primary"
      (click)="toggleGroupByCluster()"
    >
      <mat-icon>grain</mat-icon>
      Group By Cluster
    </button>
    <button
      *ngIf="(featureFlags | async)?.TopicDiscovery && searchPredicate?.clusterIds?.length === 1"
      mat-raised-button
      color="primary"
      (click)="showAllSamples()"
    >
      <mat-icon>list_alt</mat-icon>
      Show all samples
    </button>
    <button color="primary" mat-raised-button (click)="generateMapping()">
      <mat-icon>map</mat-icon>
      Generate Mapping
    </button>
    <button mat-raised-button color="primary" [matMenuTriggerFor]="upload">
      <mat-icon>add_circle</mat-icon>
      Add Sample
    </button>
    <mat-menu #upload="matMenu">
      <button mat-menu-item (click)="createText()">
        <span>Text Sample</span>
      </button>
      <button mat-menu-item (click)="navigateToSearch()">
        <span>Tenant Search</span>
      </button>
      <ng-container *ngIf="(featureFlags | async)?.FullDocumentSupport">
        <button mat-menu-item (click)="uploadDocuments()">
          <span>Upload Document(s)</span>
        </button>
      </ng-container>
      <ng-container *ngIf="(featureFlags | async)?.DataGenie">
        <button mat-menu-item (click)="dataGenie()">
          <span>Data Genie</span>
        </button>
      </ng-container>
    </mat-menu>
  </div>
  <ng-container *ngIf="reviewView === ReviewViewEnum.ClusterView">
    <div
      *ngIf="reviewView === ReviewViewEnum.ClusterView"
      fxLayoutGap="1em"
      fxFlex="50"
      class="right-menu"
      fxLayoutAlign="end center"
    >
      <button mat-raised-button color="primary" (click)="navigateToPreviousView()">
        <mat-icon>dvr</mat-icon>
        Toggle Sample
      </button>
    </div>
  </ng-container>
</div>
